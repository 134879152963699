import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Rapid = lazy(() => import('sections/hero/Rapid.js'));
const PreciosRapid = lazy(() => import('sections/services/PreciosRapid.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const GarantiaRapid = lazy(() => import('sections/hero/GarantiaRapid.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const EspecSSLRapid = lazy(() => import('sections/services/EspecSSLRapid.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const FaqGeotrust = lazy(() => import('../../sections/services/FaqGeotrust')); // Asegúrate de que la ruta sea correcta
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>RapidSSL: Certificados SSL de servidor seguro - Colombia</title>
          <meta name="description" content="Obtén certificados de servidor seguro SSL RapidSSL para una seguridad rápida y confiable en tu sitio web. Protege la información de tus usuarios con cifrado sólido." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <Rapid />
            <PreciosRapid />
            <EspecSSLRapid />
            <GarantiaRapid />
            <SelloSitelock />
            <SeguridadInformatica />
            <FaqGeotrust />
            <BotonSanGeo />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`